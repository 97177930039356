import ERROR_CODE from 'src/constants/ErrorCodes';
import { STAGE } from 'src/constants/StageConfig';

/**
 * Constant object to store paths for all pages
 */
export const PAGE_PATHS = {
  HOME: '/',
  HOME_PAGE: '/home',
  ACTION_CENTER: '/actionCenter',
  VIEW_EMPLOYEE_DETAILS: '/employeeDirectory/:concernedEmployeeIdentifier/details',
  CREATE_OPSHR_CASE_WF: '/employeeDirectory/:concernedEmployeeId/:concernedEmployeeAlias/details',
  CASE_LIST: '/caseList/',
  CASE_DETAILS: '/caseDetails/:useCase/:caseId',
  CASE_SEARCH_PAGE: '/caseSearchResult',
  TASK_DETAILS: '/taskDetails/:useCase/:taskId',
  CREATE_CASE: '/createCase/:useCase/:concernedEmployeeId',
  EMPLOYEE_SEARCH_PAGE: '/employeeDirectory',
  CONCERNED_ENTITY_CASES: '/concernedEntityCases',
  MESSAGE_BOARD_PAGE: '/messsageBoard',
  WORKFLOW: '/workflow',
  WORKFLOW_STEP: '/workflow',
  NUPT_EMPLOYEE_INFO: '/nuptEmployeeInfo',
  FAILURE_PAGE: '/error/:errorCode',
  FAILURE_PAGE_ROOT: '/error/',
  TCUI_SPOOFED_USER: '?runas=brest',
  ACCESS_DENIED: '/accessDenied',
  UNAUTHORIZED_ACCESS: '/unauthorizedAccess',
  CASE_CREATION_COMPLETED: '/caseCreationCompleted',
  CLONE_CASE: '/cloneCase/:useCase/:concernedEmployeeId/:caseId',
  FEDERATE_LOGIN: '/login',
  IDP_RESPONSE: '/idpresponse',
  TERMINATION_VALIDATION_SESSION: '/terminationValidation/session',
  COE_SUPPORT_CASE_DETAILS: '/support/supportCaseDetails/:useCase/:caseId',
  SUPPORT: '/support',
  INSIGHTS: '/insights',
  HR_INSIGHTS: '/hrInsights',
  OUT_OF_OFFICE: '/outOfOffice',
  SITE_WALK_UP: '/siteWalkUp',
  SITE_WALK_UP_CREATE_CASE: '/siteWalkUp/createCase/:concernedAlias',
  SITE_WALK_UP_SUPPORT: '/siteWalkUp/support',
  SITE_WALK_UP_SUPPORT_CONTENT: '/siteWalkUp/support/content',
  MYTIME_REPORTING: '/myTimeReporting',
  SCHEDULE_AUDIT: '/scheduleAudit',
  HAND_OFF_PAGE: '/handOff',
  CREATE_SEVERANCE_PAGE: '/terminations/severances/:employeeId',
  SEVERANCE_PAGE: '/terminations/severances/:employeeId/:severanceId',
  INITIATE_SEVERANCE_PAGE: '/terminations/severances',
  SITE_CLOSURE: '/siteClosure',
  ALL: '*',
};

/**
 * Enum to store path route names
 */
export const PATH_ROUTE_NAMES = {
  CASE_DETAILS: 'caseDetails',
  TASK_DETAILS: 'taskDetails',
};

/**
 * Constants Objcet to store Page Names
 */
export const PAGE_NAMES = {
  HOME: '',
  ACTION_CENTER: 'Action Center',
  EMPLOYEE_DIRECTORY: 'Employee Directory',
  NUPT_EMPLOYEE_INFO: 'NUPT Employee Info',
  VIEW_EMPLOYEE_DETAILS: 'Employee Details',
  CASE_DETAILS: 'Case Details',
  CASE_LIST: 'Case List',
  CASE_SEARCH: 'Case Search',
  OOO_PROXY_LIST: 'OOO Proxy List',
  ACCESS_DENIED: 'Access Denied',
  PAGE_NOT_FOUND: 'Page not found',
  CASE_CREATION_COMPLETED: 'Case Creation Completed',
  TASK_DETAILS: 'Task Details',
  SITE_WALK_UP: 'Site walk-up',
  CREATE_CASE_WALKUP: 'Record walk-up',
  WALKUP_SUPPORT: 'Provide support',
  MYTIME_REPORTING: 'MyTime Reporting',
  PAGE_NOT_AVAILABLE: 'Page Not Available',
  HAND_OFF_PAGE: 'Hand off cases page',
  SEVERANCE_PAGE: 'Severance',
  INITIATE_SEVERANCE_PAGE: 'Initiate Severance',
  MESSAGE_BOARD: 'Message Board',
  HR_INSIGHTS: 'HR Insights',
};

/**
 * Mapping from Error message to Error code.
 */
//TODO: ADD OTHER ERROR MAPPING WHEN REQUIRED
export const GLOBAL_ERROR_MESSAGE_TO_ERROR_CODE: Record<ERROR_CODE, string> = {
  [ERROR_CODE.SITE_FAILURE]: '5xx',
  [ERROR_CODE.WORKFLOW_FAILURE]: '5xx',
  [ERROR_CODE.WORKFLOW_TIMEOUT]: '5xx',
  [ERROR_CODE.EMPLOYEE_NOT_FOUND]: '404',
  [ERROR_CODE.PXT_ONE_CASE_ACCESS_ERROR]: '403',
  [ERROR_CODE.ATP_CASE_ACCESS_ERROR]: '403',
};

/**
 * Constant object to store endpoints for all APIs
 */
export const API_ENDPOINTS = {
  ADD_OR_UPDATE_CORRESPONDENCE: '/api/hrxPluginService/add-or-update-correspondence',
  ADD_OR_UPDATE_NOTES: '/api/hrxPluginService/add-or-update-notes',
  CREATE_CASE: '/api/hrxPluginService/create-case',
  EXECUTE_KHONSU_OPERATION: '/api/hrxPluginService/execute-khonsu-operation',
  EXECUTE_MYHR_GATEWAY_OPERATION: '/api/hrxPluginService/execute-myhr-gateway-operation',
  EXECUTE_PLUGIN: '/api/hrxPluginService/execute-plugin',
  GET_APPLICABLE_WIDGETS: '/api/hrxCoreService/get-applicable-widgets',
  GET_ATTACHMENT_DOWNLOAD_URL: '/api/hrxPluginService/get-attachment-download-url',
  GET_ATTACHMENT_UPLOAD_URL: '/api/hrxPluginService/get-attachment-upload-url',
  GET_ATTACHMENT_URL: '/api/hrxPluginService/get-attachment',
  GET_ATTENDANCE_STATUS: '/api/hrxPluginService/get-attendance-status',
  GET_BALANCES: '/api/hrxPluginService/get-balances',
  GET_CASE: '/api/hrxPluginService/get-case',
  GET_CASES: '/api/hrxPluginService/get-cases',
  GET_CASES_ADDITIONAL_DETAILS: '/api/hrxPluginService/get-cases-additional-details',
  GET_CONTACT_ALERTS: '/api/hrxPluginService/get-contact-alerts',
  GET_CASE_ADDITIONAL_DETAILS: '/api/hrxPluginService/get-case-additional-details',
  DELETE_CASE_ADDITIONAL_DETAILS: '/api/hrxPluginService/delete-case-additional-details',
  GET_OPS_CONTACT_DRIVERS: '/api/hrxPluginService/get-ops-contact-drivers',
  GET_EMAIL_BODY_DETAILS: '/api/hrxPluginService/get-email-body-details',
  GET_EMAIL_TEMPLATES: '/api/hrxPluginService/get-email-templates',
  GET_QUICK_TEXTS: '/api/hrxPluginService/get-quick-texts',
  GET_EMPLOYEES_SUPPORTED_BY_HR: '/api/hrxPluginService/get-employees-supported-by-hr',
  GET_EMPLOYEE_DETAILS: '/api/hrxPluginService/get-employee-details',
  GET_BULK_EMPLOYEE_DETAILS: '/api/hrxPluginService/get-employees',
  GET_EMPLOYEE_TICKETS: '/api/hrxPluginService/get-employee-tickets',
  GET_EMPLOYEE_SUPPORTING_HRS: '/api/hrxPluginService/get-employee-supporting-hrs',
  GET_ENTITY_DETAILS: '/api/hrxPluginService/get-entity-details',
  GET_FEDERATE_TOKENS: '/api/hrxCoreService/get-federate-tokens',
  GET_HR_BANNER: '/api/hrxPluginService/get-hr-banner',
  GET_IVY_TASKS: '/api/hrxPluginService/get-ivy-tasks',
  GET_NEWS: '/api/hrxPluginService/get-news',
  GET_PAYCODES: '/api/hrxPluginService/get-paycodes',
  GET_PAYPERIODS: '/api/hrxPluginService/get-payperiods',
  GET_PERSON_ID_FROM_BADGE_ID: '/api/hrxPluginService/get-person-id-from-badge-id',
  GET_PUNCHES: '/api/hrxPluginService/get-punches',
  GET_PUNCH_CORRECTION_VIEW_WIDGETS: '/api/hrxCoreService/get-punch-correction-view-widgets',
  GET_QUEUES: '/api/hrxPluginService/get-queues',
  GET_SECRET: '/api/hrxCoreService/get-secret',
  GET_SHIFTS: '/api/hrxPluginService/get-shifts',
  GET_TASK: '/api/hrxPluginService/get-task',
  GET_USERS: '/api/hrxPluginService/get-users',
  GET_WORKER_COMP_CASES: '/api/hrxPluginService/get-worker-compensation-cases',
  HANDOFF_CASE: '/api/hrxPluginService/handOff-case',
  IDENTIFY_HR_ROLE: '/api/hrxAccessManagementService/identify-hr-role',
  CHECK_USER_PERMISSIONS: '/api/hrxAccessManagementService/check-user-permissions',
  GET_PERMISSIONS_SCOPE_BY_USER: '/api/hrxAccessManagementService/get-permissions-scope-by-user',
  GET_USER_PERMISSIONS: '/api/hrxAccessManagementService/get-user-permissions',
  KATAL_LOGGER: '/log',
  SEARCH_EMPLOYEE: '/api/hrxPluginService/search-employee',
  SEND_EMAIL: '/api/hrxPluginService/send-email',
  UPDATE_CASE: '/api/hrxPluginService/update-case',
  UPDATE_CASES: '/api/hrxPluginService/update-cases',
  UPDATE_TASK: '/api/hrxPluginService/update-task',
  UPDATE_TASKS: '/api/hrxPluginService/update-tasks',
  WORKFLOW_UI_BASE: '/api/hrxPluginService/workflow-ui-base',
  SAVE_PACKET: '/api/hrxPluginService/save-appeal-packet',
  GET_PACKET: '/api/hrxPluginService/get-appeal-packet',
  SAVE_PACKET_V2: '/api/hrxPluginService/save-appeal-packet-v2',
  GET_PACKET_V2: '/api/hrxPluginService/get-appeal-packet-v2',
  CREATE_PACKET_V2: '/api/hrxPluginService/create-appeal-packet-v2',
  REFRESH_PACKET_DOMAIN_V2: '/api/hrxPluginService/refresh-appeal-domain-v2',
  REFRESH_PACKET_V2: '/api/hrxPluginService/refresh-appeal-packet-v2',
  GET_TERMINATION_VALIDATION_SESSION: '/api/hrxPluginService/get-termination-validation-session',
  GET_TERMINATION_VALIDATION_FORM_DATA: '/api/hrxPluginService/get-termination-validation-form-data',
  GET_TERMINATION_METADATA: '/api/hrxPluginService/get-termination-metadata',
  SAVE_TERMINATION_VALIDATION_FORM_DATA: '/api/hrxPluginService/save-termination-validation-form-data',
  SUBMIT_TERMINATION_VALIDATION_FORM: '/api/hrxPluginService/submit-termination-validation-form',
  GET_ROUTING_CONFIGURATIONS: '/api/hrxPluginService/get-routing-destinations',
  CANCEL_TERMINATION_VALIDATION_FORM: '/api/hrxPluginService/cancel-termination-validation-form',
  GET_TEXT_WITH_MERGE_FIELDS_RESOLVED: '/api/hrxPluginService/get-text-with-merge-fields-resolved',
  GET_HRP_OOO_RECORDS: '/api/hrxPluginService/get-hrp-ooo-records',
  GET_HRP_OOO: '/api/hrxPluginService/get-hrp-ooo',
  CREATE_HRP_OOO: '/api/hrxPluginService/create-hrp-ooo',
  UPDATE_HRP_OOO: '/api/hrxPluginService/update-hrp-ooo',
  DELETE_HRP_OOO: '/api/hrxPluginService/delete-hrp-ooo',
  GET_UNIFIED_DATA: '/api/hrxPluginService/get-employee-unified-data',
  ADD_RECENTLY_VIEWED_ITEM: '/api/hrxCoreService/add-recently-viewed-item',
  GET_RECENTLY_VIEWED_ITEMS: '/api/hrxCoreService/get-recently-viewed-items',
  GET_WHATSNEW_ENGAGEMENT: '/api/hrxCoreService/get-whatsnew-engagement',
  UPDATE_WHATSNEW_SEEN_ID: '/api/hrxCoreService/update-whatsnew-seen-id',
  UPDATE_WHATSNEW_SKIP_POPUP: '/api/hrxCoreService/update-whatsnew-skip-popup',
  GET_WORKFLOW_ID: '/api/hrxPluginService/get-workflow-id',
  GET_STATIC_CONTENT: '/api/hrxPluginService/get-static-content',
  GET_ATTENDANCE_BALANCES: '/api/hrxPluginService/get-attendance-balances',
  GET_WALK_UP_SUPPORT_INFO: '/api/hrxPluginService/get-walk-up-support-info',
  GET_APPCONFIG_DATA: '/api/hrxPluginService/get-appconfig-data',
  PUSH_NOTIFICATION: '/api/hrxPluginService/push-notification',
  UPDATE_IMPERSONATION: '/api/hrxPluginService/update-impersonation',
  MANAGE_WATCHERS: '/api/hrxPluginService/manage-watchers',
  GET_FEEDBACK_CASES_BY_EMPLOYEE_ID: '/api/hrxPluginService/get-feedback-cases-by-employee-id',
  UPLOAD_IMAGE: '/api/hrxPluginService/upload-image',
  RESEND_APPEAL_EMAIL: '/api/hrxPluginService/resend-appeal-email',
  GET_KDS_CONTENT: '/api/hrxPluginService/search-content',
  GET_ACCOMMODATION_DECISION_REQUEST: '/api/hrxPluginService/get-dls-decision-requests',
  GET_PI_ENGAGE_DETAILS: '/api/hrxPluginService/get-pi-engage-details',
  GET_INSIGHTS_S3_PRESIGNED_URL: '/api/hrxPluginService/get-insights-s3-presigned-url',
  GET_CASE_SUMMARY: '/api/hrxPluginService/get-case-summary',
  CONVERT_TEXT_TO_JSON_REQUEST: '/api/hrxPluginService/convert-text-to-json',
  SEARCH_DEVIATIONS: '/api/hrxPluginService/search-deviations',
  GET_SHIFT_PATTERN_DETAILS: '/api/hrxPluginService/get-shift-pattern-details',
};

export interface ExternalURLS {
  LiveSupportLOA: string;
  LiveSupportHRSupport: string;
  LiveSupportPanoCreateCase: string;
  ManageMyLeave: string;
  PEOPLE_PORTAL: string;
  PHONE_TOOL: string;
  ADMIN_DIRECTORY: string;
  HR_KEY: string;
  ALLSEC: string;
  SIM_T: string;
  SMART_FORMS: string;
  QUICK_SIGHT: string;
  CLARITY: string;
  PANORAMA: string;
  ACCESS_DENIED_PAGE_IMAGE: string;
  TCUI_PORTAL?: string; //TODO prod link
  INTERNAL_SERVER_ERROR_IMAGE: string;
  AMAZON_LOGO: string;
  HRSC_LOGO: string;
  HELP_CREATE_ISSUE: string;
  SUBMIT_FEEDBACK: string;
  CAMP: string;
  LENEL: string;
  FCLM: string;
  WIKI: string;
  HR_TOOLKIT: string;
  EMPLOYEE_POLICIES: string;
  DLS: string;
  IVY: string;
  ADAPT: string;
  PAGE_NOT_FOUND_IMAGE: string;
  SUCCESS_IMAGE: string;
  CUBE_NEWS: string;
  KNOWLEDGE_BASE: string;
  EXACT: string;
  UMBRELLA: string;
  ONBASE: string;
  WELCOME_BANNER_IMAGE: string;
  BEXT_DASHBOARD: string;
  SUPPORT: string;
  GSS: string;
  ATOZ_MY_HR: string;
  MAPLE_MY_SUPPORT_ASSIGNMENTS: string;
  TERMINATION_VALIDATION_WIKI: string;
  DALI_LINK_PREFIX: string;
  PXT_ONE_QUICKSIGHT_DASHBOARD: string;
  PXT_ONE_NO_ACCESS_IMAGE: string;
  ENGAGE: string;
  CONNECTIONS: string;
  POLICY_2_0: string;
  LABOR_SCHEDULING_TOOL: string;
  HRSC_CASE_METRICS: string;
  HAWKEYE_HMD: string;
  RISE: string;
  MAPLE_SELF_SERVICE_USER_PROFILE: string;
  CREATE_ACCESS_ISSUE_TICKET: string;
  POLICY_TEAM_WIKI: string;
  REQUEST_NEW_POLICY: string;
  REQUEST_POLICY_CHANGE: string;
  ORIGAMI: string;
  PXT_ONE_FAQ_WIKI: string;
  CLEAR_COOKIES_GUIDE: string;
}

export type ExternalURLKey = keyof ExternalURLS;

/**
 * Constants all External URLs by stage
 */
export const EXTERNAL_URLS: Record<STAGE, ExternalURLS> = {
  [STAGE.DEV]: {
    LiveSupportLOA:
      'https://essportal.integ.amazon.com/myhr/livehelp/landing?category=HR+Support+on+behalf+of+an+employee&type=Chat+with+Leave+and+Accommodations',
    LiveSupportHRSupport:
      'https://essportal.integ.amazon.com/myhr/livehelp/landing?category=HR+Support+on+behalf+of+an+employee&type=Chat+with+HR+Support',
    LiveSupportPanoCreateCase: 'https://essportal.integ.amazon.com/myhr/livehelp/landing?category=Test&type=HRSC',
    ManageMyLeave: 'https://essportal.integ.amazon.com/myhr/category/time,_leave_of_absence_and_accommodation',
    PEOPLE_PORTAL: 'https://peopleportal.hr.corp.amazon.com/',
    PHONE_TOOL: 'https://phonetool.amazon.com/users/',
    HR_KEY: 'https://hrkey.amazon.com/',
    ALLSEC: 'https://www.allsechro.com/AmazonSSO/',
    SIM_T: 'https://t.corp.amazon.com/',
    SMART_FORMS: 'https://smarthr-beta.integ.amazon.com',
    QUICK_SIGHT: 'https://wisdom.corp.amazon.com/quicksight',
    CLARITY: 'https://clarity.people-insight.a2z.com/',
    PANORAMA: 'https://amazon-hr.my.salesforce.com/',
    BEXT_DASHBOARD:
      'https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/e7c88c97-a921-4e74-9d24-d4884cc06f10/sheets/e7c88c97-a921-4e74-9d24-d4884cc06f10_7f1c1d1c-1375-4b10-90ee-d4fcab29f7b5',
    TCUI_PORTAL: 'https://weui-test.workevents.a2z.com/teams-timecard/',
    INTERNAL_SERVER_ERROR_IMAGE: 'https://alpha.hrsc.pxt.amazon.dev/assets/FAILURE_ERROR_IMAGE.png',
    AMAZON_LOGO: 'https://alpha.hrsc.pxt.amazon.dev/assets/AMAZON_LOGO.png', //TODO Remove Amazon LOGO
    HRSC_LOGO: 'https://alpha.hrsc.pxt.amazon.dev/assets/HRSC_Logo_RV_V2.png',
    HELP_CREATE_ISSUE: 'https://sim.amazon.com/issues/create?template=50c2f318-6858-4623-ab68-8e0c13e0f18a',
    SUBMIT_FEEDBACK: 'https://survey.fieldsense.whs.amazon.dev/survey/252cfef4-b4ae-46bf-99c4-6450708c0b60',
    CAMP: 'https://camp-beta.corp.amazon.com/',
    LENEL: 'http://badgescanreport-beta.corp.amazon.com/',
    FCLM: 'https://fclm-portal.integ.amazon.com/',
    WIKI: 'https://w.amazon.com/bin/view/Main/',
    HR_TOOLKIT: 'https://inside.hr.amazon.dev/content/inside/us/en/toolkits/for-hr.html',
    EMPLOYEE_POLICIES: 'https://inside.hr.amazon.dev/us/en/employment/policies-global.html',
    DLS: 'https://landdqa-amazonessportal.cs45.force.com/ESSPortal/s/',
    IVY: 'https://beta.ivy.talent.a2z.com/',
    ADAPT: 'https://adapt-gamma.amazon.com',
    ACCESS_DENIED_PAGE_IMAGE: 'https://alpha.hrsc.pxt.amazon.dev/assets/output.png',
    PAGE_NOT_FOUND_IMAGE: 'https://alpha.hrsc.pxt.amazon.dev/assets/404.png',
    SUCCESS_IMAGE: 'https://alpha.hrsc.pxt.amazon.dev/assets/success.png',
    ADMIN_DIRECTORY: 'https://admindirectory.corp.amazon.com/users/',
    CUBE_NEWS: 'https://news.cube.pxt.amazon.dev/',
    KNOWLEDGE_BASE: 'https://dev.hrsc-kb.pxt.amazon.dev/etc/amazon-hr/paths/search-proxy.html',
    EXACT: 'https://exactinvestigation.my.salesforce.com/',
    UMBRELLA: 'https://iad.umbrella.amazon.dev/sdl/dashboard',
    ONBASE: 'https://amazon.onbaseonline.com/idpappnet/',
    WELCOME_BANNER_IMAGE: 'https://alpha.hrsc.pxt.amazon.dev/assets/WELCOME_BANNER_IMAGE.png',
    SUPPORT: 'https://alpha.hrsc.pxt.amazon.dev/assets/SUPPORT.png',
    GSS: 'https://na.labor-admin.gsf.a2z.com/scheduling',
    ATOZ_MY_HR: 'https://essportal.integ.amazon.com/myhr',
    MAPLE_MY_SUPPORT_ASSIGNMENTS: 'https://maple-admin-beta.integ.amazon.com/my-support-assignments/populations',
    TERMINATION_VALIDATION_WIKI: 'https://w.amazon.com/bin/view/TerminationValidationWiki',
    DALI_LINK_PREFIX: 'https://amazonloaclaimvantage--ldpreprod.sandbox.lightning.force.com/lightning/r/',
    PXT_ONE_QUICKSIGHT_DASHBOARD:
      'https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/0bef1788-826b-446b-a62c-8dfcd1b6e298/sheets/0bef1788-826b-446b-a62c-8dfcd1b6e298_d6238e10-6672-4291-b38e-57ab0999367b',
    PXT_ONE_NO_ACCESS_IMAGE: 'https://alpha.hrsc.pxt.amazon.dev/assets/lock.svg',
    ENGAGE: 'https://na.beta.engage.amazon.dev/',
    CONNECTIONS:
      'https://connections-reporting.corp.amazon.com/#/home?currentView=ORGS&selectedTimeRangeId=static-1-month',
    POLICY_2_0: 'https://policy.a2z.com/',
    LABOR_SCHEDULING_TOOL: 'https://scheduling.amazon.com/',
    HRSC_CASE_METRICS:
      'https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/e15aa502-36cc-43aa-ac62-9cc2ec0472c6/sheets/e15aa502-36cc-43aa-ac62-9cc2ec0472c6_6e1dfb97-e3ed-4ae5-8795-0720df85bb20',
    HAWKEYE_HMD:
      'https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/efbda839-26fc-45bc-85e0-79b6af874aa3/sheets/efbda839-26fc-45bc-85e0-79b6af874aa3_fd324358-8075-4c39-89c0-6982f35cae14',
    RISE: 'https://rise.aka.amazon.com/overview',
    MAPLE_SELF_SERVICE_USER_PROFILE:
      'https://w.amazon.com/bin/view/FPDS/FPDS-DCX/DCX-Security/MapleMySupport/UserGuide/UserProfile/',
    CREATE_ACCESS_ISSUE_TICKET: 'https://t.corp.amazon.com/create/templates/6865c8f8-e4f3-4dc1-8633-c646fdcc054b',
    POLICY_TEAM_WIKI: 'https://w.amazon.com/bin/view/PXT3-policy',
    REQUEST_NEW_POLICY: 'https://issues.amazon.com/issues/create?template=199b0334-87bd-4e05-8d6b-755e49d1cfc0',
    REQUEST_POLICY_CHANGE: 'https://form.asana.com/?k=ZuEAhmZPM8P2ymblBb9-kQ&d=8442528107068',
    ORIGAMI: 'https://live.origamirisk.com/Origami/IncidentEntry/Welcome',
    PXT_ONE_FAQ_WIKI: 'https://w.amazon.com/bin/view/PXT_Apps/HRSC/Features/PXT_One_Request/FAQs/',
    CLEAR_COOKIES_GUIDE: 'https://w.amazon.com/bin/view/HRIS/How-to-clear-browser-cache-and-cookies',
  },
  [STAGE.ALPHA]: {
    LiveSupportLOA:
      'https://essportal.integ.amazon.com/myhr/livehelp/landing?category=HR+Support+on+behalf+of+an+employee&type=Chat+with+Leave+and+Accommodations',
    LiveSupportHRSupport:
      'https://essportal.integ.amazon.com/myhr/livehelp/landing?category=HR+Support+on+behalf+of+an+employee&type=Chat+with+HR+Support',
    LiveSupportPanoCreateCase: 'https://essportal.integ.amazon.com/myhr/livehelp/landing?category=Test&type=HRSC',
    ManageMyLeave: 'https://essportal.integ.amazon.com/myhr/category/time,_leave_of_absence_and_accommodation',
    PEOPLE_PORTAL: 'https://peopleportal.hr.corp.amazon.com/',
    PHONE_TOOL: 'https://phonetool.amazon.com/users/',
    HR_KEY: 'https://hrkey.amazon.com/',
    ALLSEC: 'https://www.allsechro.com/AmazonSSO/',
    SIM_T: 'https://t.corp.amazon.com/',
    SMART_FORMS: 'https://smarthr-beta.integ.amazon.com',
    QUICK_SIGHT: 'https://wisdom.corp.amazon.com/quicksight',
    CLARITY: 'https://clarity.people-insight.a2z.com/',
    PANORAMA: 'https://amazon-hr.my.salesforce.com/',
    BEXT_DASHBOARD:
      'https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/e7c88c97-a921-4e74-9d24-d4884cc06f10/sheets/e7c88c97-a921-4e74-9d24-d4884cc06f10_7f1c1d1c-1375-4b10-90ee-d4fcab29f7b5',
    TCUI_PORTAL: 'https://weui-test.workevents.a2z.com/teams-timecard/',
    INTERNAL_SERVER_ERROR_IMAGE: 'https://alpha.hrsc.pxt.amazon.dev/assets/FAILURE_ERROR_IMAGE.png',
    AMAZON_LOGO: 'https://alpha.hrsc.pxt.amazon.dev/assets/AMAZON_LOGO.png', //TODO Remove Amazon LOGO
    HRSC_LOGO: 'https://alpha.hrsc.pxt.amazon.dev/assets/HRSC_Logo_RV_V2.png',
    HELP_CREATE_ISSUE: 'https://sim.amazon.com/issues/create?template=50c2f318-6858-4623-ab68-8e0c13e0f18a',
    SUBMIT_FEEDBACK: 'https://survey.fieldsense.whs.amazon.dev/survey/252cfef4-b4ae-46bf-99c4-6450708c0b60',
    CAMP: 'https://camp-beta.corp.amazon.com/',
    LENEL: 'http://badgescanreport-beta.corp.amazon.com/',
    FCLM: 'https://fclm-portal.integ.amazon.com/',
    WIKI: 'https://w.amazon.com/bin/view/Main/',
    HR_TOOLKIT: 'https://inside.hr.amazon.dev/content/inside/us/en/toolkits/for-hr.html',
    EMPLOYEE_POLICIES: 'https://inside.hr.amazon.dev/us/en/employment/policies-global.html',
    DLS: 'https://landdqa-amazonessportal.cs45.force.com/ESSPortal/s/',
    IVY: 'https://beta.ivy.talent.a2z.com/',
    ADAPT: 'https://adapt-gamma.amazon.com',
    ACCESS_DENIED_PAGE_IMAGE: 'https://alpha.hrsc.pxt.amazon.dev/assets/output.png',
    PAGE_NOT_FOUND_IMAGE: 'https://alpha.hrsc.pxt.amazon.dev/assets/404.png',
    SUCCESS_IMAGE: 'https://alpha.hrsc.pxt.amazon.dev/assets/success.png',
    ADMIN_DIRECTORY: 'https://admindirectory.corp.amazon.com/users/',
    CUBE_NEWS: 'https://news.cube.pxt.amazon.dev/',
    KNOWLEDGE_BASE: 'https://dev.hrsc-kb.pxt.amazon.dev/etc/amazon-hr/paths/search-proxy.html',
    EXACT: 'https://exactinvestigation.my.salesforce.com/',
    UMBRELLA: 'https://iad.umbrella.amazon.dev/sdl/dashboard',
    ONBASE: 'https://amazon.onbaseonline.com/idpappnet/',
    WELCOME_BANNER_IMAGE: 'https://alpha.hrsc.pxt.amazon.dev/assets/WELCOME_BANNER_IMAGE.png',
    SUPPORT: 'https://alpha.hrsc.pxt.amazon.dev/assets/SUPPORT.png',
    GSS: 'https://na.labor-admin.gsf.a2z.com/scheduling',
    ATOZ_MY_HR: 'https://essportal.integ.amazon.com/myhr',
    MAPLE_MY_SUPPORT_ASSIGNMENTS: 'https://maple-admin-beta.integ.amazon.com/my-support-assignments/populations',
    TERMINATION_VALIDATION_WIKI: 'https://w.amazon.com/bin/view/TerminationValidationWiki',
    DALI_LINK_PREFIX: 'https://amazonloaclaimvantage--ldpreprod.sandbox.lightning.force.com/lightning/r/',
    PXT_ONE_QUICKSIGHT_DASHBOARD:
      'https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/0bef1788-826b-446b-a62c-8dfcd1b6e298/sheets/0bef1788-826b-446b-a62c-8dfcd1b6e298_d6238e10-6672-4291-b38e-57ab0999367b',
    PXT_ONE_NO_ACCESS_IMAGE: 'https://alpha.hrsc.pxt.amazon.dev/assets/lock.svg',
    ENGAGE: 'https://na.beta.engage.amazon.dev/',
    CONNECTIONS:
      'https://connections-reporting.corp.amazon.com/#/home?currentView=ORGS&selectedTimeRangeId=static-1-month',
    POLICY_2_0: 'https://policy.a2z.com/',
    LABOR_SCHEDULING_TOOL: 'https://scheduling.amazon.com/',
    HRSC_CASE_METRICS:
      'https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/e15aa502-36cc-43aa-ac62-9cc2ec0472c6/sheets/e15aa502-36cc-43aa-ac62-9cc2ec0472c6_6e1dfb97-e3ed-4ae5-8795-0720df85bb20',
    HAWKEYE_HMD:
      'https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/efbda839-26fc-45bc-85e0-79b6af874aa3/sheets/efbda839-26fc-45bc-85e0-79b6af874aa3_fd324358-8075-4c39-89c0-6982f35cae14',
    RISE: 'https://rise.aka.amazon.com/overview',
    MAPLE_SELF_SERVICE_USER_PROFILE:
      'https://w.amazon.com/bin/view/FPDS/FPDS-DCX/DCX-Security/MapleMySupport/UserGuide/UserProfile/',
    CREATE_ACCESS_ISSUE_TICKET: 'https://t.corp.amazon.com/create/templates/6865c8f8-e4f3-4dc1-8633-c646fdcc054b',
    POLICY_TEAM_WIKI: 'https://w.amazon.com/bin/view/PXT3-policy',
    REQUEST_NEW_POLICY: 'https://issues.amazon.com/issues/create?template=199b0334-87bd-4e05-8d6b-755e49d1cfc0',
    REQUEST_POLICY_CHANGE: 'https://form.asana.com/?k=ZuEAhmZPM8P2ymblBb9-kQ&d=8442528107068',
    ORIGAMI: 'https://live.origamirisk.com/Origami/IncidentEntry/Welcome',
    PXT_ONE_FAQ_WIKI: 'https://w.amazon.com/bin/view/PXT_Apps/HRSC/Features/PXT_One_Request/FAQs/',
    CLEAR_COOKIES_GUIDE: 'https://w.amazon.com/bin/view/HRIS/How-to-clear-browser-cache-and-cookies',
  },
  [STAGE.BETA]: {
    LiveSupportLOA:
      'https://essportal.integ.amazon.com/myhr/livehelp/landing?category=HR+Support+on+behalf+of+an+employee&type=Chat+with+Leave+and+Accommodations',
    LiveSupportHRSupport:
      'https://essportal.integ.amazon.com/myhr/livehelp/landing?category=HR+Support+on+behalf+of+an+employee&type=Chat+with+HR+Support',
    LiveSupportPanoCreateCase: 'https://essportal.integ.amazon.com/myhr/livehelp/landing?category=Test&type=HRSC',
    ManageMyLeave: 'https://essportal.integ.amazon.com/myhr/category/time,_leave_of_absence_and_accommodation',
    PEOPLE_PORTAL: 'https://peopleportal.hr.corp.amazon.com/',
    PHONE_TOOL: 'https://phonetool.amazon.com/users/',
    HR_KEY: 'https://hrkey.amazon.com/',
    ALLSEC: 'https://www.allsechro.com/AmazonSSO/',
    SIM_T: 'https://t.corp.amazon.com/',
    SMART_FORMS: 'https://smarthr-beta.integ.amazon.com',
    QUICK_SIGHT: 'https://wisdom.corp.amazon.com/quicksight',
    CLARITY: 'https://clarity.people-insight.a2z.com/',
    PANORAMA: 'https://amazon-hr.my.salesforce.com/',
    BEXT_DASHBOARD:
      'https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/e7c88c97-a921-4e74-9d24-d4884cc06f10/sheets/e7c88c97-a921-4e74-9d24-d4884cc06f10_7f1c1d1c-1375-4b10-90ee-d4fcab29f7b5',
    TCUI_PORTAL: 'https://weui-test.workevents.a2z.com/teams-timecard/',
    ACCESS_DENIED_PAGE_IMAGE: 'https://beta.hrsc.pxt.amazon.dev/assets/UNAUTHORIZED.png',
    INTERNAL_SERVER_ERROR_IMAGE: 'https://beta.hrsc.pxt.amazon.dev/assets/FAILURE_ERROR_IMAGE.png',
    AMAZON_LOGO: 'https://beta.hrsc.pxt.amazon.dev/assets/AMAZON_LOGO.png', //TODO Remove Amazon LOGO
    HRSC_LOGO: 'https://beta.hrsc.pxt.amazon.dev/assets/HRSC_Logo_RV_V2.png',
    HELP_CREATE_ISSUE: 'https://sim.amazon.com/issues/create?template=50c2f318-6858-4623-ab68-8e0c13e0f18a',
    SUBMIT_FEEDBACK: 'https://survey.fieldsense.whs.amazon.dev/survey/252cfef4-b4ae-46bf-99c4-6450708c0b60',
    CAMP: 'https://camp-beta.corp.amazon.com/',
    LENEL: 'http://badgescanreport-beta.corp.amazon.com/',
    FCLM: 'https://fclm-portal.integ.amazon.com/',
    WIKI: 'https://w.amazon.com/bin/view/Main/',
    HR_TOOLKIT: 'https://inside.hr.amazon.dev/content/inside/us/en/toolkits/for-hr.html',
    EMPLOYEE_POLICIES: 'https://inside.hr.amazon.dev/us/en/employment/policies-global.html',
    DLS: 'https://landdqa-amazonessportal.cs45.force.com/ESSPortal/s/',
    IVY: 'https://beta.ivy.talent.a2z.com/',
    ADAPT: 'https://adapt-gamma.amazon.com',
    PAGE_NOT_FOUND_IMAGE: 'https://beta.hrsc.pxt.amazon.dev/assets/404.png',
    SUCCESS_IMAGE: 'https://beta.hrsc.pxt.amazon.dev/assets/success.png',
    ADMIN_DIRECTORY: 'https://admindirectory.corp.amazon.com/users/',
    CUBE_NEWS: 'https://news.cube.pxt.amazon.dev/',
    KNOWLEDGE_BASE: 'https://dev.hrsc-kb.pxt.amazon.dev/etc/amazon-hr/paths/search-proxy.html',
    EXACT: 'https://exactinvestigation.my.salesforce.com/',
    UMBRELLA: 'https://iad.umbrella.amazon.dev/sdl/dashboard',
    ONBASE: 'https://amazon.onbaseonline.com/idpappnet/',
    WELCOME_BANNER_IMAGE: 'https://beta.hrsc.pxt.amazon.dev/assets/WELCOME_BANNER_IMAGE.png',
    SUPPORT: 'https://beta.hrsc.pxt.amazon.dev/assets/SUPPORT.png',
    GSS: 'https://na.labor-admin.gsf.a2z.com/scheduling',
    ATOZ_MY_HR: 'https://essportal.integ.amazon.com/myhr',
    MAPLE_MY_SUPPORT_ASSIGNMENTS: 'https://maple-admin-beta.integ.amazon.com/my-support-assignments/populations',
    TERMINATION_VALIDATION_WIKI: 'https://w.amazon.com/bin/view/TerminationValidationWiki',
    DALI_LINK_PREFIX: 'https://amazonloaclaimvantage--ldpreprod.sandbox.lightning.force.com/lightning/r/',
    PXT_ONE_QUICKSIGHT_DASHBOARD:
      'https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/0bef1788-826b-446b-a62c-8dfcd1b6e298/sheets/0bef1788-826b-446b-a62c-8dfcd1b6e298_d6238e10-6672-4291-b38e-57ab0999367b',
    PXT_ONE_NO_ACCESS_IMAGE: 'https://beta.hrsc.pxt.amazon.dev/assets/lock.svg',
    ENGAGE: 'https://na.beta.engage.amazon.dev/',
    CONNECTIONS:
      'https://connections-reporting.corp.amazon.com/#/home?currentView=ORGS&selectedTimeRangeId=static-1-month',
    POLICY_2_0: 'https://policy.a2z.com/',
    LABOR_SCHEDULING_TOOL: 'https://scheduling.amazon.com/',
    HRSC_CASE_METRICS:
      'https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/e15aa502-36cc-43aa-ac62-9cc2ec0472c6/sheets/e15aa502-36cc-43aa-ac62-9cc2ec0472c6_6e1dfb97-e3ed-4ae5-8795-0720df85bb20',
    HAWKEYE_HMD:
      'https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/efbda839-26fc-45bc-85e0-79b6af874aa3/sheets/efbda839-26fc-45bc-85e0-79b6af874aa3_fd324358-8075-4c39-89c0-6982f35cae14',
    RISE: 'https://rise.aka.amazon.com/overview',
    MAPLE_SELF_SERVICE_USER_PROFILE:
      'https://w.amazon.com/bin/view/FPDS/FPDS-DCX/DCX-Security/MapleMySupport/UserGuide/UserProfile/',
    CREATE_ACCESS_ISSUE_TICKET: 'https://t.corp.amazon.com/create/templates/6865c8f8-e4f3-4dc1-8633-c646fdcc054b',
    POLICY_TEAM_WIKI: 'https://w.amazon.com/bin/view/PXT3-policy',
    REQUEST_NEW_POLICY: 'https://issues.amazon.com/issues/create?template=199b0334-87bd-4e05-8d6b-755e49d1cfc0',
    REQUEST_POLICY_CHANGE: 'https://form.asana.com/?k=ZuEAhmZPM8P2ymblBb9-kQ&d=8442528107068',
    ORIGAMI: 'https://live.origamirisk.com/Origami/IncidentEntry/Welcome',
    PXT_ONE_FAQ_WIKI: 'https://w.amazon.com/bin/view/PXT_Apps/HRSC/Features/PXT_One_Request/FAQs/',
    CLEAR_COOKIES_GUIDE: 'https://w.amazon.com/bin/view/HRIS/How-to-clear-browser-cache-and-cookies',
  },
  [STAGE.GAMMA]: {
    LiveSupportLOA:
      'https://essportal.integ.amazon.com/myhr/livehelp/landing?category=HR+Support+on+behalf+of+an+employee&type=Chat+with+Leave+and+Accommodations',
    LiveSupportHRSupport:
      'https://essportal.integ.amazon.com/myhr/livehelp/landing?category=HR+Support+on+behalf+of+an+employee&type=Chat+with+HR+Support',
    LiveSupportPanoCreateCase: 'https://atoz.amazon.work/myhr/livehelp/landing?category=Test&type=HRSC',
    ManageMyLeave: 'https://atoz.amazon.work/myhr/category/time,_leave_of_absence_and_accommodation',
    PEOPLE_PORTAL: 'https://peopleportal.hr.corp.amazon.com/',
    PHONE_TOOL: 'https://phonetool.amazon.com/users/',
    HR_KEY: 'https://hrkey.amazon.com/',
    ALLSEC: 'https://www.allsechro.com/AmazonSSO/',
    SIM_T: 'https://t.corp.amazon.com/',
    SMART_FORMS: 'https://smarthr-gamma.integ.amazon.com',
    QUICK_SIGHT: 'https://wisdom.corp.amazon.com/quicksight',
    CLARITY: 'https://clarity.people-insight.a2z.com/',
    PANORAMA: 'https://amazon-hr.my.salesforce.com/',
    BEXT_DASHBOARD:
      'https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/e7c88c97-a921-4e74-9d24-d4884cc06f10/sheets/e7c88c97-a921-4e74-9d24-d4884cc06f10_7f1c1d1c-1375-4b10-90ee-d4fcab29f7b5',
    INTERNAL_SERVER_ERROR_IMAGE: 'https://gamma.hrsc.pxt.amazon.dev/assets/FAILURE_ERROR_IMAGE.png',
    HELP_CREATE_ISSUE: 'https://sim.amazon.com/issues/create?template=50c2f318-6858-4623-ab68-8e0c13e0f18a',
    AMAZON_LOGO: 'https://gamma.hrsc.pxt.amazon.dev/assets/AMAZON_LOGO.png', //TODO Remove Amazon LOGO
    HRSC_LOGO: 'https://gamma.hrsc.pxt.amazon.dev/assets/HRSC_Logo_RV_V2.png',
    SUBMIT_FEEDBACK: 'https://survey.fieldsense.whs.amazon.dev/survey/252cfef4-b4ae-46bf-99c4-6450708c0b60',
    CAMP: 'https://camp-gamma.corp.amazon.com/',
    LENEL: 'https://acs-badgescanreport.corp.amazon.com/',
    FCLM: 'https://fclm-portal.integ.amazon.com/',
    WIKI: 'https://w.amazon.com/bin/view/Main/',
    HR_TOOLKIT: 'https://inside.hr.amazon.dev/content/inside/us/en/toolkits/for-hr.html',
    EMPLOYEE_POLICIES: 'https://inside.hr.amazon.dev/us/en/employment/policies-global.html',
    DLS: 'https://landdqa-amazonessportal.cs45.force.com/ESSPortal/s/',
    IVY: 'https://gamma.ivy.talent.a2z.com/',
    ADAPT: 'https://adapt-gamma.amazon.com',
    TCUI_PORTAL: 'https://weui-gamma.workevents.a2z.com/teams-timecard/',
    ACCESS_DENIED_PAGE_IMAGE: 'https://gamma.hrsc.pxt.amazon.dev/assets/UNAUTHORIZED.png',
    PAGE_NOT_FOUND_IMAGE: 'https://gamma.hrsc.pxt.amazon.dev/assets/404.png',
    SUCCESS_IMAGE: 'https://gamma.hrsc.pxt.amazon.dev/assets/success.png',
    ADMIN_DIRECTORY: 'https://admindirectory.corp.amazon.com/users/',
    CUBE_NEWS: 'https://news.cube.pxt.amazon.dev/',
    KNOWLEDGE_BASE: 'https://stage.hrsc-kb.pxt.amazon.dev/etc/amazon-hr/paths/search-proxy.html',
    EXACT: 'https://exactinvestigation.my.salesforce.com/',
    UMBRELLA: 'https://iad.umbrella.amazon.dev/sdl/dashboard',
    ONBASE: 'https://amazon.onbaseonline.com/idpappnet/',
    WELCOME_BANNER_IMAGE: 'https://gamma.hrsc.pxt.amazon.dev/assets/WELCOME_BANNER_IMAGE.png',
    SUPPORT: 'https://gamma.hrsc.pxt.amazon.dev/assets/SUPPORT.png',
    GSS: 'https://na.labor-admin.gsf.a2z.com/scheduling',
    ATOZ_MY_HR: 'https://essportal.integ.amazon.com/myhr',
    MAPLE_MY_SUPPORT_ASSIGNMENTS: 'https://maple-admin-beta.integ.amazon.com/my-support-assignments/populations',
    TERMINATION_VALIDATION_WIKI: 'https://w.amazon.com/bin/view/TerminationValidationWiki',
    DALI_LINK_PREFIX: 'https://amazonloaclaimvantage--ldpreprod.sandbox.lightning.force.com/lightning/r/',
    PXT_ONE_QUICKSIGHT_DASHBOARD:
      'https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/0bef1788-826b-446b-a62c-8dfcd1b6e298/sheets/0bef1788-826b-446b-a62c-8dfcd1b6e298_d6238e10-6672-4291-b38e-57ab0999367b',
    PXT_ONE_NO_ACCESS_IMAGE: 'https://gamma.hrsc.pxt.amazon.dev/assets/lock.svg',
    ENGAGE: 'https://na.gamma.engage.amazon.dev/',
    CONNECTIONS:
      'https://connections-reporting.corp.amazon.com/#/home?currentView=ORGS&selectedTimeRangeId=static-1-month',
    POLICY_2_0: 'https://policy.a2z.com/',
    LABOR_SCHEDULING_TOOL: 'https://scheduling.amazon.com/',
    HRSC_CASE_METRICS:
      'https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/e15aa502-36cc-43aa-ac62-9cc2ec0472c6/sheets/e15aa502-36cc-43aa-ac62-9cc2ec0472c6_6e1dfb97-e3ed-4ae5-8795-0720df85bb20',
    HAWKEYE_HMD:
      'https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/efbda839-26fc-45bc-85e0-79b6af874aa3/sheets/efbda839-26fc-45bc-85e0-79b6af874aa3_fd324358-8075-4c39-89c0-6982f35cae14',
    RISE: 'https://rise.aka.amazon.com/overview',
    MAPLE_SELF_SERVICE_USER_PROFILE:
      'https://w.amazon.com/bin/view/FPDS/FPDS-DCX/DCX-Security/MapleMySupport/UserGuide/UserProfile/',
    CREATE_ACCESS_ISSUE_TICKET: 'https://t.corp.amazon.com/create/templates/6865c8f8-e4f3-4dc1-8633-c646fdcc054b',
    POLICY_TEAM_WIKI: 'https://w.amazon.com/bin/view/PXT3-policy',
    REQUEST_NEW_POLICY: 'https://issues.amazon.com/issues/create?template=199b0334-87bd-4e05-8d6b-755e49d1cfc0',
    REQUEST_POLICY_CHANGE: 'https://form.asana.com/?k=ZuEAhmZPM8P2ymblBb9-kQ&d=8442528107068',
    ORIGAMI: 'https://live.origamirisk.com/Origami/IncidentEntry/Welcome',
    PXT_ONE_FAQ_WIKI: 'https://w.amazon.com/bin/view/PXT_Apps/HRSC/Features/PXT_One_Request/FAQs/',
    CLEAR_COOKIES_GUIDE: 'https://w.amazon.com/bin/view/HRIS/How-to-clear-browser-cache-and-cookies',
  },
  [STAGE.ONEBOX]: {
    LiveSupportLOA:
      'https://atoz.amazon.work/myhr/livehelp/landing?category=HR+Support+on+behalf+of+an+employee&type=Chat+with+Leave+and+Accommodations',
    LiveSupportHRSupport:
      'https://atoz.amazon.work/myhr/livehelp/landing?category=HR+Support+on+behalf+of+an+employee&type=Chat+with+HR+Support',
    LiveSupportPanoCreateCase: 'https://atoz.amazon.work/myhr/livehelp/landing?category=Test&type=HRSC',
    ManageMyLeave: 'https://atoz.amazon.work/myhr/category/time,_leave_of_absence_and_accommodation',
    PEOPLE_PORTAL: 'https://peopleportal.hr.corp.amazon.com/',
    PHONE_TOOL: 'https://phonetool.amazon.com/users/',
    HR_KEY: 'https://hrkey.amazon.com/',
    ALLSEC: 'https://www.allsechro.com/AmazonSSO/',
    SIM_T: 'https://t.corp.amazon.com/',
    SMART_FORMS: 'https://smartforms.corp.amazon.com/',
    QUICK_SIGHT: 'https://wisdom.corp.amazon.com/quicksight',
    CLARITY: 'https://clarity.people-insight.a2z.com/',
    PANORAMA: 'https://amazon-hr.my.salesforce.com/',
    BEXT_DASHBOARD:
      'https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/e7c88c97-a921-4e74-9d24-d4884cc06f10/sheets/e7c88c97-a921-4e74-9d24-d4884cc06f10_7f1c1d1c-1375-4b10-90ee-d4fcab29f7b5',
    HELP_CREATE_ISSUE: 'https://sim.amazon.com/issues/create?template=50c2f318-6858-4623-ab68-8e0c13e0f18a',
    AMAZON_LOGO: 'https://hrsc.pxt.amazon.dev/assets/AMAZON_LOGO.png', //TODO Remove Amazon LOGO
    HRSC_LOGO: 'https://hrsc.pxt.amazon.dev/assets/HRSC_Logo_RV_V2.png',
    SUBMIT_FEEDBACK: 'https://survey.fieldsense.whs.amazon.dev/survey/252cfef4-b4ae-46bf-99c4-6450708c0b60',
    CAMP: 'https://camp.corp.amazon.com/',
    LENEL: 'http://badgescanreport.corp.amazon.com/',
    FCLM: 'https://fclm-portal.amazon.com/',
    WIKI: 'https://w.amazon.com/bin/view/Main/',
    HR_TOOLKIT: 'https://inside.hr.amazon.dev/content/inside/us/en/toolkits/for-hr.html',
    EMPLOYEE_POLICIES: 'https://inside.hr.amazon.dev/us/en/employment/policies-global.html',
    DLS: 'http://dls.idp.amazon-corp.com/',
    IVY: 'https://ivy.talent.a2z.com/',
    ADAPT: 'https://adapt-iad.amazon.com/',
    TCUI_PORTAL: 'https://weui.workevents.a2z.com/teams-timecard/',
    INTERNAL_SERVER_ERROR_IMAGE: 'https://hrsc.pxt.amazon.dev/assets/FAILURE_ERROR_IMAGE.png',
    ACCESS_DENIED_PAGE_IMAGE: 'https://hrsc.pxt.amazon.dev/assets/output.png',
    PAGE_NOT_FOUND_IMAGE: 'https://hrsc.pxt.amazon.dev/assets/404.png',
    SUCCESS_IMAGE: 'https://hrsc.pxt.amazon.dev/assets/success.png',
    ADMIN_DIRECTORY: 'https://admindirectory.corp.amazon.com/users/',
    CUBE_NEWS: 'https://news.cube.pxt.amazon.dev/',
    KNOWLEDGE_BASE: 'https://hrsc-kb.pxt.amazon.dev/etc/amazon-hr/paths/search-proxy.html',
    EXACT: 'https://exactinvestigation.my.salesforce.com/',
    UMBRELLA: 'https://iad.umbrella.amazon.dev/sdl/dashboard',
    ONBASE: 'https://amazon.onbaseonline.com/idpappnet/',
    WELCOME_BANNER_IMAGE: 'https://hrsc.pxt.amazon.dev/assets/WELCOME_BANNER_IMAGE.png',
    SUPPORT: 'https://hrsc.pxt.amazon.dev/assets/SUPPORT.png',
    GSS: 'https://na.labor-admin.gsf.a2z.com/scheduling',
    ATOZ_MY_HR: 'https://atoz.amazon.work/myhr',
    MAPLE_MY_SUPPORT_ASSIGNMENTS: 'https://maple-admin-prod.corp.amazon.com/my-support-assignments/populations',
    TERMINATION_VALIDATION_WIKI: 'https://w.amazon.com/bin/view/TerminationValidationWiki',
    DALI_LINK_PREFIX: 'https://amazonloaclaimvantage.lightning.force.com/lightning/r/',
    PXT_ONE_QUICKSIGHT_DASHBOARD:
      'https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/0bef1788-826b-446b-a62c-8dfcd1b6e298/sheets/0bef1788-826b-446b-a62c-8dfcd1b6e298_d6238e10-6672-4291-b38e-57ab0999367b',
    PXT_ONE_NO_ACCESS_IMAGE: 'https://hrsc.pxt.amazon.dev/assets/lock.svg',
    ENGAGE: 'https://na.engage.amazon.dev/',
    CONNECTIONS:
      'https://connections-reporting.corp.amazon.com/#/home?currentView=ORGS&selectedTimeRangeId=static-1-month',
    POLICY_2_0: 'https://policy.a2z.com/',
    LABOR_SCHEDULING_TOOL: 'https://scheduling.amazon.com/',
    HRSC_CASE_METRICS:
      'https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/e15aa502-36cc-43aa-ac62-9cc2ec0472c6/sheets/e15aa502-36cc-43aa-ac62-9cc2ec0472c6_6e1dfb97-e3ed-4ae5-8795-0720df85bb20',
    HAWKEYE_HMD:
      'https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/efbda839-26fc-45bc-85e0-79b6af874aa3/sheets/efbda839-26fc-45bc-85e0-79b6af874aa3_fd324358-8075-4c39-89c0-6982f35cae14',
    RISE: 'https://rise.aka.amazon.com/overview',
    MAPLE_SELF_SERVICE_USER_PROFILE:
      'https://w.amazon.com/bin/view/FPDS/FPDS-DCX/DCX-Security/MapleMySupport/UserGuide/UserProfile/',
    CREATE_ACCESS_ISSUE_TICKET: 'https://t.corp.amazon.com/create/templates/6865c8f8-e4f3-4dc1-8633-c646fdcc054b',
    POLICY_TEAM_WIKI: 'https://w.amazon.com/bin/view/PXT3-policy',
    REQUEST_NEW_POLICY: 'https://issues.amazon.com/issues/create?template=199b0334-87bd-4e05-8d6b-755e49d1cfc0',
    REQUEST_POLICY_CHANGE: 'https://form.asana.com/?k=ZuEAhmZPM8P2ymblBb9-kQ&d=8442528107068',
    ORIGAMI: 'https://live.origamirisk.com/Origami/IncidentEntry/Welcome',
    PXT_ONE_FAQ_WIKI: 'https://w.amazon.com/bin/view/PXT_Apps/HRSC/Features/PXT_One_Request/FAQs/',
    CLEAR_COOKIES_GUIDE: 'https://w.amazon.com/bin/view/HRIS/How-to-clear-browser-cache-and-cookies',
  },
  [STAGE.PROD]: {
    LiveSupportLOA:
      'https://atoz.amazon.work/myhr/livehelp/landing?category=HR+Support+on+behalf+of+an+employee&type=Chat+with+Leave+and+Accommodations',
    LiveSupportHRSupport:
      'https://atoz.amazon.work/myhr/livehelp/landing?category=HR+Support+on+behalf+of+an+employee&type=Chat+with+HR+Support',
    LiveSupportPanoCreateCase: 'https://atoz.amazon.work/myhr/livehelp/landing?category=Test&type=HRSC',
    ManageMyLeave: 'https://atoz.amazon.work/myhr/category/time,_leave_of_absence_and_accommodation',
    PEOPLE_PORTAL: 'https://peopleportal.hr.corp.amazon.com/',
    PHONE_TOOL: 'https://phonetool.amazon.com/users/',
    HR_KEY: 'https://hrkey.amazon.com/',
    ALLSEC: 'https://www.allsechro.com/AmazonSSO/',
    SIM_T: 'https://t.corp.amazon.com/',
    SMART_FORMS: 'https://smartforms.corp.amazon.com/',
    QUICK_SIGHT: 'https://wisdom.corp.amazon.com/quicksight',
    CLARITY: 'https://clarity.people-insight.a2z.com/',
    PANORAMA: 'https://amazon-hr.my.salesforce.com/',
    BEXT_DASHBOARD:
      'https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/e7c88c97-a921-4e74-9d24-d4884cc06f10/sheets/e7c88c97-a921-4e74-9d24-d4884cc06f10_7f1c1d1c-1375-4b10-90ee-d4fcab29f7b5',
    HELP_CREATE_ISSUE: 'https://sim.amazon.com/issues/create?template=50c2f318-6858-4623-ab68-8e0c13e0f18a',
    AMAZON_LOGO: 'https://hrsc.pxt.amazon.dev/assets/AMAZON_LOGO.png', //TODO Remove Amazon LOGO
    HRSC_LOGO: 'https://hrsc.pxt.amazon.dev/assets/HRSC_Logo_RV_V2.png',
    SUBMIT_FEEDBACK: 'https://survey.fieldsense.whs.amazon.dev/survey/252cfef4-b4ae-46bf-99c4-6450708c0b60',
    CAMP: 'https://camp.corp.amazon.com/',
    LENEL: 'http://badgescanreport.corp.amazon.com/',
    FCLM: 'https://fclm-portal.amazon.com/',
    WIKI: 'https://w.amazon.com/bin/view/Main/',
    HR_TOOLKIT: 'https://inside.hr.amazon.dev/content/inside/us/en/toolkits/for-hr.html',
    EMPLOYEE_POLICIES: 'https://inside.hr.amazon.dev/us/en/employment/policies-global.html',
    DLS: 'http://dls.idp.amazon-corp.com/',
    IVY: 'https://ivy.talent.a2z.com/',
    ADAPT: 'https://adapt-iad.amazon.com/',
    TCUI_PORTAL: 'https://weui.workevents.a2z.com/teams-timecard/',
    INTERNAL_SERVER_ERROR_IMAGE: 'https://hrsc.pxt.amazon.dev/assets/FAILURE_ERROR_IMAGE.png',
    ACCESS_DENIED_PAGE_IMAGE: 'https://hrsc.pxt.amazon.dev/assets/output.png',
    PAGE_NOT_FOUND_IMAGE: 'https://hrsc.pxt.amazon.dev/assets/404.png',
    SUCCESS_IMAGE: 'https://hrsc.pxt.amazon.dev/assets/success.png',
    ADMIN_DIRECTORY: 'https://admindirectory.corp.amazon.com/users/',
    CUBE_NEWS: 'https://news.cube.pxt.amazon.dev/',
    KNOWLEDGE_BASE: 'https://hrsc-kb.pxt.amazon.dev/etc/amazon-hr/paths/search-proxy.html',
    EXACT: 'https://exactinvestigation.my.salesforce.com/',
    UMBRELLA: 'https://iad.umbrella.amazon.dev/sdl/dashboard',
    ONBASE: 'https://amazon.onbaseonline.com/idpappnet/',
    WELCOME_BANNER_IMAGE: 'https://hrsc.pxt.amazon.dev/assets/WELCOME_BANNER_IMAGE.png',
    SUPPORT: 'https://hrsc.pxt.amazon.dev/assets/SUPPORT.png',
    GSS: 'https://na.labor-admin.gsf.a2z.com/scheduling',
    ATOZ_MY_HR: 'https://atoz.amazon.work/myhr',
    MAPLE_MY_SUPPORT_ASSIGNMENTS: 'https://maple-admin-prod.corp.amazon.com/my-support-assignments/populations',
    TERMINATION_VALIDATION_WIKI: 'https://w.amazon.com/bin/view/TerminationValidationWiki',
    DALI_LINK_PREFIX: 'https://amazonloaclaimvantage.lightning.force.com/lightning/r/',
    PXT_ONE_QUICKSIGHT_DASHBOARD:
      'https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/0bef1788-826b-446b-a62c-8dfcd1b6e298/sheets/0bef1788-826b-446b-a62c-8dfcd1b6e298_d6238e10-6672-4291-b38e-57ab0999367b',
    PXT_ONE_NO_ACCESS_IMAGE: 'https://hrsc.pxt.amazon.dev/assets/lock.svg',
    ENGAGE: 'https://na.engage.amazon.dev/',
    CONNECTIONS:
      'https://connections-reporting.corp.amazon.com/#/home?currentView=ORGS&selectedTimeRangeId=static-1-month',
    POLICY_2_0: 'https://policy.a2z.com/',
    LABOR_SCHEDULING_TOOL: 'https://scheduling.amazon.com/',
    HRSC_CASE_METRICS:
      'https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/e15aa502-36cc-43aa-ac62-9cc2ec0472c6/sheets/e15aa502-36cc-43aa-ac62-9cc2ec0472c6_6e1dfb97-e3ed-4ae5-8795-0720df85bb20',
    HAWKEYE_HMD:
      'https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/efbda839-26fc-45bc-85e0-79b6af874aa3/sheets/efbda839-26fc-45bc-85e0-79b6af874aa3_fd324358-8075-4c39-89c0-6982f35cae14',
    RISE: 'https://rise.aka.amazon.com/overview',
    MAPLE_SELF_SERVICE_USER_PROFILE:
      'https://w.amazon.com/bin/view/FPDS/FPDS-DCX/DCX-Security/MapleMySupport/UserGuide/UserProfile/',
    CREATE_ACCESS_ISSUE_TICKET: 'https://t.corp.amazon.com/create/templates/6865c8f8-e4f3-4dc1-8633-c646fdcc054b',
    POLICY_TEAM_WIKI: 'https://w.amazon.com/bin/view/PXT3-policy',
    REQUEST_NEW_POLICY: 'https://issues.amazon.com/issues/create?template=199b0334-87bd-4e05-8d6b-755e49d1cfc0',
    REQUEST_POLICY_CHANGE: 'https://form.asana.com/?k=ZuEAhmZPM8P2ymblBb9-kQ&d=8442528107068',
    ORIGAMI: 'https://live.origamirisk.com/Origami/IncidentEntry/Welcome',
    PXT_ONE_FAQ_WIKI: 'https://w.amazon.com/bin/view/PXT_Apps/HRSC/Features/PXT_One_Request/FAQs/',
    CLEAR_COOKIES_GUIDE: 'https://w.amazon.com/bin/view/HRIS/How-to-clear-browser-cache-and-cookies',
  },
};

export const LOCAL_API_GATEWAY_END_POINT = 'https://hrxproxy.us-west-2.alpha.backend-hrsc.pxt.amazon.dev';

/*
 * Constants for MultiTab-view page urls
 */
export const CASE_DETAILS_ROUTER_LINK_PREFIX = '/caseDetails/';
export const TASK_DETAILS_ROUTER_LINK_PREFIX = '/taskDetails';
export const EMPLOYEE_PROFILE_ROUTER_LINK_PREFIX = '/employeeDirectory/';
export const CREATE_CASE_PREFIX = '/createCase/';
export const CLONE_CASE_PREFIX = '/cloneCase/';
export const COE_SUPPORT_CASE_DETAILS_PREFIX = '/support/supportCaseDetails/';
export const SITE_WALK_UP_CREATE_CASE_PREFIX = '/siteWalkUp/createCase/';
export const SITE_WALK_UP_SUPPORT_PREFIX = '/siteWalkUp/support';
export const SEVERANCE_PREFIX = '/terminations/severances';
